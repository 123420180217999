const EVENT_FLUSH_INTERVAL = 10000; // 10 seconds
const EVENT_FLUSH_SIZE = 20;
const apiHost = process.env.API_URL + '/analytics'; // Analytics API URL

const eventBuffer = [];
const props = {};

let analyticsCsrf;

export async function init(csrf) {
  analyticsCsrf = csrf;

  setProperty('pageUrl', window.location.href);
  setProperty('referrer', document.referrer);
  setProperty('title', document.title);
  setProperty('languages', window.navigator.languages);
  setProperty('language', window.navigator.language);
  setProperty('platform', getPlatform());
  setProperty('screenSize', [window.screen.width, window.screen.height]);
  setProperty('devicePixelRatio', window.devicePixelRatio);
  setProperty('networkType', navigator.connection?.effectiveType);

  const sessionId = window.sessionStorage.getItem('a_sid');
  const userId = window.localStorage.getItem('a_uid');

  // Populate eventBuffer with props (for events that are tracked before init)
  eventBuffer.forEach((ev) => {
    ev.props = { ...props };
  });

  if (sessionId && userId) {
    setProperty('sessionId', sessionId);
    setProperty('userId', userId);
  } else {
    const res = await fetch(apiHost + '/init', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        'x-csrf-token': analyticsCsrf,
      },
      body: JSON.stringify(props),
    });

    const data = await res.json();

    setProperty('sessionId', data.sessionId);
    setProperty('userId', data.userId);

    window.sessionStorage.setItem('a_sid', data.sessionId);
    window.localStorage.setItem('a_uid', data.userId);
  }

  trackEvent('page_view');

  window.addEventListener('beforeunload', flush);
  window.addEventListener('pagehide', flush);
  window.addEventListener('load', () => {
    const performance = window.performance.toJSON();

    trackEvent('page_load', {
      time: performance.loadEventEnd - performance.navigationStart,
    });
  });

  setInterval(flush, EVENT_FLUSH_INTERVAL);
}

export function setProperty(key, value) {
  props[key] = value;
}

export function trackEvent(type, eventProps = {}) {
  eventBuffer.push({
    type,
    props: {
      ...props,
    },
    data: eventProps,
    timestamp: Date.now(),
  });

  if (eventBuffer.length >= EVENT_FLUSH_SIZE) {
    flush();
  }
}

export function flush() {
  if (eventBuffer.length === 0) {
    return;
  }

  fetch(apiHost + '/events', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      'x-csrf-token': analyticsCsrf,
    },
    body: JSON.stringify({
      events: eventBuffer.splice(0, eventBuffer.length),
    }),
  }).catch(console.error);
}

export function getPlatform() {
  if (window.navigator.userAgentData?.platform) {
    return window.navigator.userAgentData.platform.toLowerCase();
  }

  let platform = window.navigator.platform.toLowerCase();
  if (platform.match(/^win/)) return 'windows';
  if (platform.match(/^mac/)) return 'mac';
  if (platform.match(/^linux armv/)) return 'android';
  if (platform.match(/^linux/)) return 'linux';
  if (platform.match(/^(iphone|ios|ipad)/)) return 'ios';
  if (platform.match(/^android/)) return 'android';

  return platform;
}
